import React from 'react';
import { Card, CardContent, Box, Typography, Avatar, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

const baseUrl = 'https://api.ozenfinance.com';

const ProfileCard = ({ cardInfo }) => {
  if (!cardInfo) {
    return <Typography>Загрузка информации о профиле...</Typography>;
  }

  const { name, product, schedule, daysOff, photo, contact, smUrl } = cardInfo;
  const photoUrl = photo ? `${baseUrl}/${photo}` : "https://via.placeholder.com/80";
  const whatsappUrl = `https://wa.me/${formatPhoneNumber(contact)}`;
  const instagramUsername = smUrl === 'инста' ? 'zuck' : smUrl;
  const instagramUrl = `https://www.instagram.com/${instagramUsername}`;

  return (
    <Card sx={styles.card}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar src={photoUrl} sx={styles.avatar} />
          <Typography variant="h6" sx={styles.name}>{name}</Typography>
          <InfoItem label="Продает" value={product} />
          <InfoItem label="График работы" value={schedule} />
          <InfoItem label="Выходной" value={daysOff} />
          <SocialButtons whatsappUrl={whatsappUrl} instagramUrl={instagramUrl} />
        </Box>
      </CardContent>
    </Card>
  );
};

const InfoItem = ({ label, value }) => (
  <Typography variant="body2" color="text.secondary" sx={styles.infoItem}>
    <strong>{label}:</strong> {value}
  </Typography>
);

const SocialButtons = ({ whatsappUrl, instagramUrl }) => (
  <Box mt={2} width="100%" display="flex" justifyContent="space-between" gap={1}>
    <SocialButton href={whatsappUrl} icon={<WhatsAppIcon />} label="WhatsApp" color="#25D366" hoverColor="#1DA754" />
    <SocialButton href={instagramUrl} icon={<InstagramIcon />} label="Instagram" color="#000" hoverColor="#444" />
  </Box>
);

const SocialButton = ({ href, icon, label, color, hoverColor }) => (
  <Button
    variant="contained"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    startIcon={icon}
    sx={styles.socialButton(color, hoverColor)}
  >
    {label}
  </Button>
);

const formatPhoneNumber = (phoneNumber) => {
  let formattedNumber = phoneNumber.replace(/[^0-9+]/g, '');
  if (formattedNumber.startsWith('+79')) {
    formattedNumber = '+7' + '7' + formattedNumber.substring(3);
  }
  return formattedNumber;
};

const styles = {
  card: {
    width: '100%',
    margin: 'auto',
    borderRadius: 4,
    backgroundColor: '#fff',
    p: 2,
    boxShadow: "none",
    '@media (max-width: 600px)': {
      p: 1,
    }
  },
  avatar: {
    width: 80,
    height: 80,
    marginBottom: 2,
    boxShadow: "none"
  },
  name: {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%'
  },
  infoItem: {
    width: '100%',
    textAlign: 'left'
  },
  socialButton: (color, hoverColor) => ({
    backgroundColor: color,
    color: '#fff',
    borderRadius: '25px',
    flex: 1,
    textTransform: 'none',
    boxShadow: "none",
    '&:hover': {
      backgroundColor: hoverColor,
      boxShadow: "none"
    }
  })
};

export default ProfileCard;