import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, TextField, Button, Typography, IconButton, Rating, Modal, Slide, Alert, Chip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '').slice(1);
  if (phoneNumber.length < 4) return `+7 (${phoneNumber}`;
  if (phoneNumber.length < 7) return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  if (phoneNumber.length < 9) return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  return `+7 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 8)}-${phoneNumber.slice(8, 10)}`;
};

const ReviewForm = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [formData, setFormData] = useState({
    rating: 0,
    review: '',
    name: '',
    phone: '',
    code: '',
  });
  const [errors, setErrors] = useState({});
  const [isRatingValid, setIsRatingValid] = useState(true);
  const [showSmsCodeField, setShowSmsCodeField] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const negativeTags = ['Некачественный товар', 'Обман с весами', 'Грубое обслуживание', 'Завышенные цены', 'Антисанитария'];
  const neutralTags = ['Средние цены', 'Обычный ассортимент', 'Нормальное обслуживание', 'Стандартное качество', 'Типичный торговец'];
  const positiveTags = ['Отличное качество', 'Честные весы', 'Вежливый продавец', 'Выгодные цены', 'Свежие продукты'];

  useEffect(() => {
    if (formData.rating >= 1 && formData.rating <= 2) {
      setTags(negativeTags);
    } else if (formData.rating === 3) {
      setTags(neutralTags);
    } else if (formData.rating >= 4 && formData.rating <= 5) {
      setTags(positiveTags);
    } else {
      setTags([]);
    }
    setSelectedTags([]);
  }, [formData.rating]);

  const validate = () => {
    let tempErrors = {};
    tempErrors.rating = formData.rating === 0 ? 'Обязательное поле' : '';
    tempErrors.review = formData.review ? '' : 'Обязательное поле';
    tempErrors.name = formData.name ? '' : 'Обязательное поле';
    tempErrors.phone = formData.phone ? '' : 'Обязательное поле';
    setErrors(tempErrors);
    setIsRatingValid(formData.rating !== 0);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'code') {
      const codeValue = value.replace(/\D/g, '').slice(0, 4);
      setFormData({ ...formData, [name]: codeValue });
    } else {
      setFormData({
        ...formData,
        [name]: name === 'phone' ? formatPhoneNumber(value) : value,
      });
    }
  };

  const handleRatingChange = (event, newValue) => {
    setFormData({
      ...formData,
      rating: newValue,
    });
    setIsRatingValid(newValue !== 0);
  };

  const sendPhoneNumber = async () => {
    const phoneDigits = formData.phone.replace(/[^\d]/g, '');
    const countryCode = phoneDigits.charAt(0);
    const mobileNumber = phoneDigits.slice(1);

    try {
      const response = await axios.post(
          'https://api.ozenfinance.com/api/v1/public/merchant/review/auth',
          {
            countryCode,
            mobileNumber,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
      );
      console.log('SMS sent successfully:', response.data);
      setShowSmsCodeField(true);
    } catch (error) {
      console.error('Error sending SMS:', error);
      setShowErrorAlert(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!showSmsCodeField) {
      if (validate()) {
        await sendPhoneNumber();
      }
    } else {
      await handleSmsCodeSubmit();
    }
  };

  const handleSmsCodeSubmit = async () => {
    if (formData.code.length === 4) {
      const phoneDigits = formData.phone.replace(/[^\d]/g, '');
      const mobile = {
        countryCode: phoneDigits.charAt(0),
        mobileNumber: phoneDigits.slice(1),
      };

      const reviewData = {
        id: null,
        cardCode: code,
        grade: formData.rating,
        review: formData.review,
        especialLike: null,
        name: formData.name,
        created: null,
        mobile: mobile,
        files: null,
        code: formData.code,
      };

      console.log('Review Data:', reviewData);

      try {
        const response = await axios.post('https://api.ozenfinance.com/api/v1/public/merchant/review', reviewData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Review submitted successfully:', response.data);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          navigate(-1);
        }, 3000);
      } catch (error) {
        console.error('Error submitting review:', error);
        setShowErrorAlert(true);
      }
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleTagClick = (tag) => {
    let newSelectedTags;
    let newReview;

    if (selectedTags.includes(tag)) {
      newSelectedTags = selectedTags.filter(t => t !== tag);
      newReview = newSelectedTags.join(', ');
    } else {
      newSelectedTags = [...selectedTags, tag];
      newReview = newSelectedTags.join(', ');
    }

    setSelectedTags(newSelectedTags);
    setFormData({ ...formData, review: newReview });
  };

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty': {
      color: isRatingValid ? theme.palette.grey[300] : theme.palette.error.main,
    },
    '& .MuiRating-iconFilled': {
      color: theme.palette.warning.main,
    },
    '& .MuiRating-icon': {
      fontSize: '4.5rem',
    },
  }));

  useEffect(() => {
    if (showErrorAlert) {
      const timer = setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showErrorAlert]);

  return (
      <Box sx={{ p: 1, pb: '100px', pt: '20px' }}>
        <Slide direction="down" in={showErrorAlert} mountOnEnter unmountOnExit>
          <Alert
              severity="error"
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 9999,
              }}
          >
            {showSmsCodeField ? 'Неверный код SMS' : 'Ошибка отправки SMS'}
          </Alert>
        </Slide>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ ml: 1 }}>
            Оставить отзыв
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <StyledRating
                name="rating"
                value={formData.rating}
                onChange={handleRatingChange}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarIcon fontSize="inherit" />}
            />
            {!isRatingValid && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  Пожалуйста, выберите оценку
                </Typography>
            )}
          </Box>

          {tags.length > 0 && (
              <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onClick={() => handleTagClick(tag)}
                        sx={{
                          borderRadius: '15px',
                          backgroundColor: selectedTags.includes(tag) ? 'black' : 'default',
                          color: selectedTags.includes(tag) ? 'white' : 'inherit',
                          '&:hover': {
                            backgroundColor: selectedTags.includes(tag) ? 'black' : 'default',
                          },
                        }}
                    />
                ))}
              </Box>
          )}

          <TextField
              label="Отзыв"
              variant="outlined"
              fullWidth
              multiline
              minRows={1}
              maxRows={10}
              name="review"
              value={formData.review}
              onChange={handleChange}
              error={Boolean(errors.review)}
              helperText={errors.review}
              sx={{ mb: 3, borderRadius: '25px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
          />

          <TextField
              label="Имя"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
              sx={{ mb: 3, borderRadius: '25px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
          />

          <TextField
              label="Телефон"
              variant="outlined"
              fullWidth
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              sx={{ mb: 3, borderRadius: '25px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
          />

          {showSmsCodeField && (
              <TextField
                  label="СМС-код"
                  variant="outlined"
                  fullWidth
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                  error={Boolean(errors.code)}
                  helperText=""
                  inputProps={{ maxLength: 4 }}
                  sx={{ mb: 3, borderRadius: '25px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
              />
          )}

          <Box sx={{ position: 'flexible', bottom: '0px', left: 0, width: '100%', p: 1, zIndex: 10 }}>
            <Button
                variant="outlined"
                type="submit"
                fullWidth
                sx={{
                  height: '60px',
                  border: '1px solid black',
                  color: 'black',
                  fontWeight: 'normal',
                  borderRadius: '25px',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                }}
            >
              {showSmsCodeField ? 'Подтвердить код' : 'Отправить отзыв'}
            </Button>
          </Box>
        </form>

        <Modal
            open={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}>
            <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
            <Typography id="success-modal-title" variant="h6" component="h2">
              Спасибо за ваш отзыв!
            </Typography>
            <Typography id="success-modal-description" sx={{ mt: 2 }}>
              Ваш отзыв успешно отправлен.
            </Typography>
          </Box>
        </Modal>
      </Box>
  );
};

export default ReviewForm;
