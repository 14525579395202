import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ReviewsPage from '../pages/ReviewsPage';
import CatalogPage from '../pages/CatalogPage';
import './TabsComponent.css';

const TabsComponent = ({ cardInfo }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [direction, setDirection] = useState('right');

  useEffect(() => {
    console.log('TabsComponent cardInfo:', cardInfo);
  }, [cardInfo]);

  const handleTabChange = (newValue) => {
    setDirection(activeTab < newValue ? 'right' : 'left');
    setActiveTab(newValue);
  };

  const tabs = [
    { label: 'Отзывы', component: <ReviewsPage cardInfo={cardInfo} /> },
    { label: 'Каталог', component: <CatalogPage cardInfo={cardInfo} /> }
  ];

  return (
    <Box sx={styles.container}>
      <TabButtons activeTab={activeTab} onTabChange={handleTabChange} tabs={tabs} />
      <TabContent activeTab={activeTab} direction={direction} tabs={tabs} />
    </Box>
  );
};

const TabButtons = ({ activeTab, onTabChange, tabs }) => (
  <Box sx={styles.buttonContainer}>
    {tabs.map((tab, index) => (
      <TabButton
        key={index}
        label={tab.label}
        isActive={activeTab === index}
        onClick={() => onTabChange(index)}
      />
    ))}
  </Box>
);

const TabButton = ({ label, isActive, onClick }) => (
  <Button
    onClick={onClick}
    sx={styles.tabButton(isActive)}
  >
    <Typography>{label}</Typography>
  </Button>
);

const TabContent = ({ activeTab, direction, tabs }) => (
  <Box sx={styles.content}>
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={activeTab}
        timeout={300}
        classNames={direction === 'left' ? 'slide-left' : 'slide-right'}
      >
        <Box sx={styles.tabContentWrapper}>
          {tabs[activeTab].component}
        </Box>
      </CSSTransition>
    </SwitchTransition>
  </Box>
);

const styles = {
  container: {
    width: '100%',
    px: 0,
    pb: 2
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2
  },
  tabButton: (isActive) => ({
    width: 'calc(50% - 7.5px)',
    height: 40,
    p: 2,
    bgcolor: isActive ? 'black' : 'transparent',
    color: isActive ? 'white' : 'black',
    border: '1px solid black',
    borderRadius: '25px',
    transition: 'all 0.3s ease',
    '&:hover': {
      bgcolor: isActive ? 'black' : 'transparent',
      color: isActive ? 'white' : 'black',
    },
  }),
  content: {
    width: '100%',
    mt: 2
  },
  tabContentWrapper: {
    width: '100%',
    mx: 'auto'
  }
};

export default TabsComponent;