import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru';

const Reviews = ({ reviews }) => {
  const reviewsPerPage = 5;

  const [page, setPage] = useState(1);
  const [displayedReviews, setDisplayedReviews] = useState([]);
  const loader = useRef(null);

  const loadMoreReviews = useCallback(() => {
    if (!reviews || reviews.length === 0) return;

    const sortedReviews = [...reviews].sort((a, b) => new Date(b.created) - new Date(a.created));
    const totalReviews = page * reviewsPerPage;

    setDisplayedReviews(sortedReviews.slice(0, totalReviews));
  }, [page, reviews]);

  useEffect(() => {
    loadMoreReviews();
  }, [page, loadMoreReviews]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    const currentLoader = loader.current;
    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [loader]);

  const formatDate = (dateString) => {
    const date = moment(dateString);
    if (date.isSame(moment(), 'day')) {
      return `Сегодня в ${date.format('HH:mm')}`;
    } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
      return `Вчера в ${date.format('HH:mm')}`;
    } else if (date.isSame(moment(), 'year')) {
      return date.format('D MMMM в HH:mm');
    } else {
      return date.format('D MMMM YYYY года в HH:mm');
    }
  };

  if (!reviews || reviews.length === 0) {
    return <p>No reviews available</p>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      {displayedReviews.map((review, index) => (
        <Card
          key={index}
          sx={{
            p: 1,
            backgroundColor: '#FFF',
            borderRadius: '15px',
            mb: 3,
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.0)',
          }}
        >
          <CardContent sx={{ px: 2 }}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box marginBottom={0}>
                <Typography variant="body1">
                  <strong>{review.name}</strong> {formatDate(review.created)} поставил оценку <strong>{review.grade} из 5</strong> и написал:
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ mt: 1, whiteSpace: 'pre-wrap', fontStyle: 'italic' }}>
                  {review.review}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
      <div ref={loader} style={{ height: '20px', opacity: 0 }}></div>
    </Box>
  );
};

export default Reviews;
