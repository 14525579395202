// src/context/AppServiceContext.js
import React, { createContext, useContext } from 'react';
import ApiService from '../api/api_service';

const AppServiceContext = createContext();

const AppServiceProvider = ({ children }) => {
  const apiService = new ApiService();

  return (
    <AppServiceContext.Provider value={{ getApiService: () => apiService }}>
      {children}
    </AppServiceContext.Provider>
  );
};

const useAppService = () => useContext(AppServiceContext);

export { AppServiceProvider, AppServiceContext, useAppService };
