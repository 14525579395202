import React from 'react';
import { Box, Typography } from '@mui/material';
import RatingsSummary from '../components/RatingsSummary';
import Reviews from '../components/Reviews';

const ReviewsPage = ({ cardInfo }) => {
  if (!cardInfo) {
    return <LoadingMessage />;
  }

  const { reviewCount, gradeMap, code, reviews } = cardInfo;

  if (reviewCount === 0) {
    return <NoReviewsMessage />;
  }

  return (
    <Box sx={styles.container}>
      <RatingsSummary 
        reviewCount={reviewCount} 
        gradeMap={gradeMap} 
        code={code}
      />
      <Reviews reviews={reviews} />
    </Box>
  );
};

const LoadingMessage = () => (
  <Typography variant="body1" sx={styles.message}>
    Загрузка отзывов...
  </Typography>
);

const NoReviewsMessage = () => (
  <Typography variant="body1" sx={styles.message}>
    Отзывов пока нет. Будьте первым, кто оставит отзыв!
  </Typography>
);

const styles = {
  container: {
    mt: 2
  },
  message: {
    textAlign: 'center',
    py: 2
  }
};

export default ReviewsPage;