import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackBar, setSnackBar] = useState({ open: false, message: '', severity: 'success' });

  const showSnackbar = (message, severity) => {
    setSnackBar({ open: true, message, severity });
    setTimeout(() => {
      setSnackBar({ open: false, message: '', severity: severity });
    }, 3000);
  };

  const handleCloseSnackbar = () => setSnackBar({ ...snackBar, open: false });

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={snackBar.open} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackBar.severity} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Добавляем этот хук
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};