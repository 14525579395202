import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Home from './pages/Home';
import ReviewForm from './components/ReviewForm';
import { AppServiceProvider } from './context/AppServiceContext';
import { SnackbarProvider } from './context/SnackbarContext';
import './App.css';

// Создаем компонент редиректа
const RedirectExternal = ({ url }) => {
  React.useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return null; // Компонент не рендерит ничего
};

const App = () => (
  <AppServiceProvider>
    <SnackbarProvider>
      <Router>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<RedirectExternal url="https://merchant.ozenfinance.com" />} />
          <Route path="/merchant/:code" element={<Home />} />
          <Route path="/merchant/:code/leave-review" element={<ReviewForm />} />
        </Routes>
      </Router>
    </SnackbarProvider>
  </AppServiceProvider>
);

export default App;
