import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Box, CircularProgress, Link } from '@mui/material';
import ProfileCard from '../components/ProfileCard';
import TabsComponent from '../components/TabsComponent';
import { AppServiceContext } from '../context/AppServiceContext';
import { useSnackbar } from '../context/SnackbarContext'; // Импортируем хук для работы со Snackbar

const Home = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { getApiService } = useContext(AppServiceContext);
  const { showSnackbar } = useSnackbar(); // Используем хук для показа уведомлений
  const [cardInfo, setCardInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  // Выносим логику загрузки данных в отдельную функцию
  const fetchCardInfo = useCallback(async () => {
    try {
      const apiService = getApiService();
      const data = await apiService.getCardInfo(code);
      setCardInfo(data);
    } catch (error) {
      console.error('API error:', error);
      showSnackbar('Ошибка при загрузке данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [code, getApiService, showSnackbar]);

  useEffect(() => {
    fetchCardInfo();
  }, [fetchCardInfo]);

  if (loading) {
    return (
      <Box sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pb: 4,
      position: 'relative',
    }}>
      <HeaderBackground />
      <MainContent cardInfo={cardInfo} />
      <PoweredByLink />
    </Box>
  );
};

// Выделяем фоновое изображение в отдельный компонент
const HeaderBackground = () => (
  <Box sx={{
    width: '100%',
    height: '250px',
    backgroundImage: 'url(https://i.ibb.co/LhMwT6f/background.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  }} />
);

// Выделяем основное содержимое в отдельный компонент
const MainContent = ({ cardInfo }) => (
  <>
    <Container sx={{
      position: 'absolute',
      top: '250px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <ProfileCard cardInfo={cardInfo} />
    </Container>
    <Box sx={{ width: '100%', mt: 21 }}>
      <Container>
        <TabsComponent cardInfo={cardInfo} />
      </Container>
    </Box>
  </>
);

// Выделяем ссылку "Powered by" в отдельный компонент
const PoweredByLink = () => (
  <Box sx={{
    position: 'fixed',
    top: '16px',
    right: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <Link href="https://ozenfinance.com" target="_blank" rel="noopener" underline="none">
      <Box className="gradient-border-button">
        Powered by Özen
      </Box>
    </Link>
  </Box>
);

export default Home;