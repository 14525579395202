import ApiClient from './api_client';

class ApiService {
  static cardInfoPath = '/api/v1/public/business-card/';
  static _bodyParam = 'body';

  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCardInfo(code) {
    try {
      console.log('Fetching card info for code:', code);
      const response = await this.apiClient.get(`${ApiService.cardInfoPath}${code}`);
      console.log('Received response:', response);
      return response[ApiService._bodyParam];
    } catch (error) {
      console.error('Error fetching card info:', error);
      throw error;
    }
  }
}

export default ApiService;
