// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppServiceProvider } from './context/AppServiceContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppServiceProvider>
      <App />
    </AppServiceProvider>
  </React.StrictMode>
);

// Measuring performance
reportWebVitals();
